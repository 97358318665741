/*
LA GALIGO
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Oct 31, 2022
UPDATE DATE: Oct 31, 2022
REVISION: 1
NAME: global.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: La Galigo | Liveboard
PALETTE:  1. #AE0921 / rgb(174, 9, 33) (Dark Red / Shiraz)
          2. #0274BE / rgb(2, 116, 190) (Strong Blue / Lochmara)
          3. #007D96 / rgb(0, 125, 150) (Dark Cyan / Blue Lagoon)
          4. #FFFF00 / rgb(255, 255, 0) (Pure Yellow / Yellow)
          5. #002330 / rgb(0, 35, 48) (Very Dark Blue / Daintree)
          6. #F0F3F4 / rgb(240, 243, 244) (Light Grayish Blue / Porcelain)
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@import './variable.scss';

@font-face{
  font-family: 'ClearSans';
  src: url('./../font/ClearSans.woff2') format('woff2'),
       url('./../font/ClearSans.woff') format('woff'),
       url('./../font/ClearSans.ttf') format('ttf'),
       url('./../font/ClearSans.eot') format('eot'),
       url('./../font/ClearSans.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'ClearSans';
  src: url('./../font/ClearSans-Bold.woff2') format('woff2'),
       url('./../font/ClearSans-Bold.woff') format('woff'),
       url('./../font/ClearSans-Bold.ttf') format('ttf'),
       url('./../font/ClearSans-Bold.eot') format('eot'),
       url('./../font/ClearSans-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'FuturaBT';
  src: url('./../font/FuturaBT-Book.woff2') format('woff2'),
       url('./../font/FuturaBT-Book.woff') format('woff'),
       url('./../font/FuturaBT-Book.ttf') format('ttf'),
       url('./../font/FuturaBT-Book.eot') format('eot'),
       url('./../font/FuturaBT-Book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// @font-face{
//   font-family: 'FuturaBT';
//   src: url('./../font/FuturaBT-Medium.woff2') format('woff2'),
//        url('./../font/FuturaBT-Medium.woff') format('woff'),
//        url('./../font/FuturaBT-Medium.ttf') format('ttf'),
//        url('./../font/FuturaBT-Medium.eot') format('eot'),
//        url('./../font/FuturaBT-Medium.svg') format('svg');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face{
//   font-family: 'ClearSans';
//   src: url('./../font/ClearSans-Thin.woff2') format('woff2'),
//        url('./../font/ClearSans-Thin.woff') format('woff'),
//        url('./../font/ClearSans-Thin.ttf') format('ttf'),
//        url('./../font/ClearSans-Thin.eot') format('eot'),
//        url('./../font/ClearSans-Thin.svg') format('svg');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

@font-face{
  font-family: 'Scriptina';
  src: url('./../font/Scriptina.woff2') format('woff2'),
       url('./../font/Scriptina.woff') format('woff'),
       url('./../font/Scriptina.ttf') format('ttf'),
       url('./../font/Scriptina.eot') format('eot'),
       url('./../font/Scriptina.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html{
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  // background: $colorBg;
  color: $colorText;
  font-size: 12px;
  line-height: 12px;
  // font-family: Helvetica, Arial, sans-serif;
  font-family: 'ClearSans', Helvetica, Arial, sans-serif;
  // font-feature-settings: 'ss02';
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a,
.link-look{
  text-decoration: none;
  color: $colorOne;
  @extend %transition;

  &:hover{
    color: $colorOne;
  }
  
  &[disabled]{
    pointer-events: none;
    opacity: .5;
  }
}

input[type="text"],
input[type="password"],
input[type="file"],
textarea,
button{
  display: block;
  color: $colorText;
  font-size: 16px;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  background: transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: 'ClearSans', Helvetica, Arial, sans-serif;
  @extend %transition;

  &:focus{
    outline: none;
  }

  &:disabled,
  &[disabled]{
    pointer-events: none;
    // opacity: .5;
  }

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button{
    opacity: 0;
    width: 0;
    height: 0;
    pointer-events: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

svg{
  display: inline-block;
  vertical-align: middle;
}

section{
  position: relative;
  padding: $spacing;
  box-sizing: border-box;
  // padding: $spacing;

  &.sec-white{
    background: #FFF;
    margin-bottom: $spacingDiv2;

    &.bookhead{
      margin: 20px auto;
    }

    &.sec-bottom{
      margin-bottom: 0;
    }
  }

  > h2{
    display: block;
    font-size: 16px;
    line-height: 20px;

    &.had-notif{
      position: relative;
      padding-right: 50px;
    }

    > span{
      display: block;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: $colorLabel;
      margin: $spacingDiv8 0 0 0;
    }

    .title-notif{
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 25px;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      background: transparent;
      text-align: center;
      color: #FFF;
      @extend %transition;

      &.success{
        background: $colorSuccess;
        width: 25px;
        height: 25px;
      }

      > svg{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 25px;
        width: 25px;
        transform: translate(0, -50%);
      }
    }
  }
}

header{
  position: relative;
  font-family: 'FuturaBT', 'Axiforma', Helvetica, Arial, sans-serif;
  background: #FFF;
  padding: 20px 0 0 0;
  z-index: 1;

  > .navmobile{
    display: none;
    position: absolute;
    left: 16px;
    top: 34px;
    width: 22px;
    height: 22px;

    > svg{
      width: 22px;
      height: 22px;
    }
  }

  > a{
    display: block;
    margin: 0 auto;
    width: 220px;
    height: 62px;
    object-fit: contain;
  }

  > ul{
    padding: 20px 0 10px 0;
    margin: 0 !important;
    overflow: visible !important;

    > li{
      padding: 0 !important;
      text-align: center;

      > a{
        position: relative;
        display: inline-block;
        text-transform: uppercase;
        line-height: 40px;
        height: 40px;
        border: 1px solid #212121;
        letter-spacing: 2px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #212121;
        padding: 0 15px;
        margin: 15px 0 0 0;
        border-radius: 15px;
        overflow: hidden;
        // transform: translateZ(0);
        isolation: isolate;

        > span{
          position: relative;
          z-index: 1;
        }

        &:after{
          content: '';
          pointer-events: none;
          position: absolute;
          background: #e81028;
          top: 50%;
          left: 50%;
          height: 300%;
          width: 0%;
          z-index: 0;
          transform: translate(-50%, -50%) rotate(-45deg);
          opacity: 0;
          @extend %transitionAnim;
        }

        &:hover{
          color: #FFF;
          border: 1px solid #e81028;
          // background: #e81028;

          &:after{
            width: 100%;
            opacity: 1;
          }
        }
      }

      > ul{
        display: flex;
        flex-wrap: wrap;
        @extend %transitionAnim;
    
        > li{
          position: relative;
          // display: inline-block;
          flex-grow: 1;
          padding: 15px 0 15px 0;
    
          &:after{
            content: '';
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 0;
            width: 2px;
            height: 18px;
            background: #000;
          }
    
          &:last-child{
            &:after{
              display: none;
            }
          }
    
          > a,
          > button{
            display: block;
            text-transform: uppercase;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 2px;
            padding: 10px 20px;
            color: #212121;
            text-align: center;
            font-family: 'FuturaBT', 'Axiforma', Helvetica, Arial, sans-serif;

            > svg{
              margin: -3px 0 0 10px;
            }
    
            &:hover,
            &.active{
              color: #e81028;
            }
          }

          &.more{
            > ul{
              opacity: 1;
              max-height: 350px;
            }
          }

          > ul{
            opacity: 0;
            max-height: 0;
            // display: none;
            position: absolute;
            background: #FFF;
            left: 0;
            top: 50px;
            width: 340px;
            overflow: hidden;
            transition: opacity 200ms ease-in-out !important;
            -moz-transition: opacity 200ms ease-in-out !important;
            -webkit-transition: opacity 200ms ease-in-out !important;

            > li{
              > a{
                display: block;
                text-align: left;
                padding: 10px 20px;
                font-size: 16px;
                line-height: 20px;
                color: #212121;
                text-transform: uppercase;
                letter-spacing: 2px;

                &:hover{
                  color: #e81028;
                }
              }
            }
          }
        }
      }
    }
  }
}

footer{
  font-family: 'FuturaBT', 'Axiforma', Helvetica, Arial, sans-serif;

  .footer-top{
    background: linear-gradient(360deg,#575860 0%,#3a3a3a 100%);
    padding: 40px 50px;
    overflow: auto;

    > ul{
      color: #FFF;

      > li{
        font-size: 18px;
        line-height: 22px;

        > div{
          position: relative;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 2px;
          padding: 0 0 20px 0;
          margin: 0 0 20px 0;

          &:after{
            content: '';
            position: absolute;
            bottom: 0;
            height: 1px;
            background: #FFF;
            width: 30%;
            left: 0;
          }
        }

        > ul{
          margin: -5px;
          
          > li{
            padding: 5px;

            > a{
              color: #FFF;
              letter-spacing: 1px;

              > span{
                display: inline-block;
                vertical-align: middle;
                border: 2px solid #FFF;
                border-radius: 35px;
                width: 35px;
                height: 35px;
                padding: 7.5px;
                box-sizing: border-box;
                margin: 0 10px 0 0;
                @extend %transition;

                > svg{
                  margin: -12px 0 0 0;
                }
              }
              
              &:hover{
                &.email{
                  > span{
                    background: #c4acd4;
                  }
                }

                &.wa{
                  > span{
                    background: #128c7e;
                  }
                }
              }
            }
          }
          
          &.payment-list{
            margin: -10px;

            > li{
              padding: 10px;
              width: auto;

              &:nth-child(5){
                clear: both;
              }

              > svg{
                width: 40px;
                height: 40px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    .foot-btm{
      border-top: 1px dotted rgba(255,255,255,.56);
      margin: 20px 0 0 0;
      padding: 20px 40px 0 40px;

      > svg{
        height: 66px;
        width: 176px;
      }

      > div{
        float: right;
        text-align: right;
        color: #FFF;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 2px;
        font-weight: bold;

        > ul{
          margin: 10px -8px 0 -8px;

          > li{
            display: inline-block;
            padding: 0 8px;

            > a{
              display: block;
              width: 36px;
              height: 36px;
              padding: 9px;
              box-sizing: border-box;
              color: #FFF;
              background: #818a91;
              border-radius: 3px;

              > svg{
                margin: -5px 0 0 0;
              }

              &.ig{
                background: #262626;
              }
              
              &.fb{
                background: #3b5998;
              }

              // &.gl{
              //   background: #818a91;
              // }

              &.yt{
                background: #cd201f;
              }

              &.tw{
                background: #1da1f2;
              }

              &.in{
                background: #0077b5;
              }

              &.pt{
                background: #bd081c;
              }

              // &.bl{
              //   background: #818a91;
              // }
            }
          }
        }
      }
    }
  }

  .footer-btm{
    text-align: center;
    padding: 10px 20px 50px 20px;
    background: #000;
    color: #7A7A7A;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;

    a{
      color: #0274be;
    }

    > br{
      display: none;
    }
  }
}

ul.floating{
  overflow: auto;
  margin: -($spacingDiv2);

  > li{
    float: left;
    padding: $spacingDiv2;
    box-sizing: border-box;
    width: 100%;

    @for $i from 1 through 99{
      &.lay-#{$i} { 
        width: $i * 1%;
      }
    }
    
    &.lay-auto{
      width: auto;
    }
  }
}

ul.forming{
  margin: -($spacingDiv2 - 2.5);
  padding: ($spacing - 5) 0 0 0;

  > li{
    padding: ($spacingDiv2 - 2.5);
    position: relative;

    &.anim-enter{
      @extend %anim-enter;
    }

    &.anim-enter-active{
      @extend %anim-enter-active;
    }

    &.anim-enter-done{
      @extend %anim-enter-done;
    }

    &.anim-exit{
      @extend %anim-exit;
    }

    &.anim-exit-active{
      @extend %anim-exit-active;
    }

    label{
      font-size: 12px;
      line-height: 16px;
      color: rgba($colorText, .5);
      
      &.text,
      &.text-qty,
      &.text-still{
        position: absolute;
        top: 23px;
        left: $spacing + 5;
        font-size: 14px;
        line-height: 14px;
        pointer-events: none;
        z-index: 1;
        @extend %transition;

        &.focus{
          top: 0;
          font-size: 12px;
          line-height: 12px;
          padding: 0 $spacingDiv4;
          left: $spacing;
          background: #FFF;
        }

        > span{
          color: $colorOne;

          &.count{
            display: inline-block;
            vertical-align: middle;
            font-weight: bold;
            font-size: 11px;
            line-height: 11px;
            background: $colorOne;
            color: #FFF;
            min-width: 15px;
            padding: 1px 0 3px 0;
            margin: -3px 0 0 0;
            text-align: center;
            border-radius: 16px;
          }
        }
      }
      
      &.text-qty{
        &.focus{
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      &.toggle{
        display: block;
        cursor: pointer;
        position: relative;
        padding: 6px 0 6px 40px;
        font-size: 14px;
        line-height: 18px;
        color: $colorText;

        > div{
          position: absolute;
          left: 0;
          top: 0;
          width: 30px;
          height: 30px;
          border: 1px solid rgba($colorText, .25);
          border-radius: $radius - 5;
          background: #FFF;
          box-sizing: border-box;
          @extend %transition;

          > svg{
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            color: #FFF;
            height: 26px;
            width: 26px;
            margin: 0 auto;
          }
        }

        > input{
          position: absolute;
          left: 0;
          top: 0;

          &:checked{
            ~ div{
              border: 1px solid $colorOne;
              background: $colorOne;
            }

            // &:disabled{
            //   ~ div{
            //     border: 1px solid rgba($colorText, .1);
            //   }
            // }
          }

          &:disabled{
            ~ div{
              border: 1px solid rgba($colorText, .1);
            }
          }
        }

        &:hover{
          > div{
            border: 1px solid rgba($colorText, .5);
          }
        }

        // > ul{
        //   margin: 0;

        //   > li{
        //     padding: 0;

        //     &:last-child{
        //       text-align: right;
        //       color: $colorLabel;
        //       font-size: 12px;
        //       line-height: 16px;

        //       > span{
        //         color: $colorText;
        //         font-size: 14px;
        //         line-height: 18px;
        //       }
        //     }
        //   }
        // }
      }
    }

    .input-notif{
      pointer-events: none;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 18px;
      top: 50%;
      transform-origin: center center;
      transform: translate(0, -50%);
      right: ($spacing - 5) / 2 + ($spacing - 5);
      color: #FFF;
      // background: rgba($colorLabel, .5);
      background: transparent;
      @extend %transition;

      &.success{
        background: $colorSuccess;
        width: 18px;
        height: 18px;

        > svg{
          height: 9px;
        }
      }

      &.error{
        background: $colorError;
        width: 18px;
        height: 18px;
      }

      > svg{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 8px;
        width: auto;
        transform: translate(0, -50%);
      }
    }
  
    input[type="text"],
    input[type="password"],
    textarea{
      border: 1px solid rgba($colorText, .25);
      border-radius: $radius;
      height: 45px;
      padding: 0 ($spacing - 5);
      font-size: 14px;

      &.valid{
        padding-right: ($spacing - 5) + 18 + $spacingDiv4;
      }

      &:hover{
        border: 1px solid rgba($colorText, .5);
      }

      &:focus{
        border: 1px solid $colorTwo;
      }

      &:disabled{
        border: 1px solid rgba($colorText, .1);
      }
    }

    textarea{
      resize: none;
      height: 100px;
      padding: ($spacingDiv2 + 1) ($spacing - 5);
    }

    .label-left{
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: ($spacing - 5) / 2;
      color: $colorLabel;
      height: 45px;
      line-height: 45px;
      width: 45px;
      text-align: center;
      font-weight: bold;
      box-sizing: border-box;
      z-index: 1;

      > span{
        display: inline-block;
        min-width: 16px;
        height: 16px;
        border-radius: 5px;
        background: $colorTwo;
        color: #FFF;
        font-size: 11px;
        line-height: 16px;
        margin: 0 0 0 -3px;
        padding: 0 0 2px 0;
        box-sizing: border-box;
      }
      
      > svg{
        width: auto;
        height: 20px;
        margin: -2px 0 0 0;
      }
    }

    &.has-label-left{
      label{
        &.text{
          left: (($spacing - 5) / 2) + 45;

          &.focus{
            left: $spacing;
          }
        }
      }
      
      input[type="text"],
      input[type="password"]{
        padding: 0 ($spacing - 5) 0 45px;
      }

      .react-select{
        .react-select__control{
          .react-select__value-container{
            padding: 0 ($spacing - 5) 0 45px;
          }
        }
      }
    }

    &.is-focus{
      label{
        &.text{
          font-size: 12px;
          line-height: 12px;
          top: $spacingDiv6;
          transform: translate(0, 0);
          padding: 0 $spacingDiv4;
          left: $spacingMul125;
        }
      }
    }

    .react-select{
      z-index: 0;

      .react-select__control{
        border: 1px solid rgba($colorText, .25);
        // margin: -1px 0 0 0;
        border-radius: $radius;
        // min-height: 45px;
        height: 45px;
        box-shadow: none;
        box-sizing: border-box;
        @extend %transition;

        &:hover{
          border: 1px solid rgba($colorText, .5);
        }

        &.react-select__control--is-disabled{
          background: transparent;
          border: 1px solid rgba($colorText, .1);
        }

        &.react-select__control--menu-is-open{
          border: 1px solid $colorTwo;
          border-bottom: 1px solid transparent;
          border-radius: $radius $radius 0 0;

          &:hover{
            border: 1px solid $colorTwo;
            border-bottom: 1px solid transparent;
          }

          .react-select__indicators{
            .react-select__dropdown-indicator{
              svg{
                transform: rotate(-180deg);
              }
            }
          }
        }

        .react-select__value-container{
          padding: 0 ($spacing - 5);
          margin: -1px 0 0 0;

          .react-select__placeholder{
            font-size: 12px;
            line-height: 16px;
            color: $colorText;
          }

          .react-select__single-value{
            font-size: 14px;
            line-height: 18px;
            margin: 0;

            > svg{
              display: none;
            }
          }

          .react-select__input-container{
            padding: 0;
            margin: 0;
            font-size: 14px;
            line-height: 18px;
          }
        }

        .react-select__indicators{
          margin: -1px 0 0 0;

          .react-select__indicator-separator{
            margin: $spacingDiv15 0;
            background: rgba($colorText, .25);
          }

          .react-select__indicator{
            cursor: pointer;
          }

          .react-select__dropdown-indicator{
            svg{
              @extend %transition;
            }
          }
        }
      }
    }
  }

  &.phone-input{
    margin: -7px 0 0 0;
    padding: 7px 0 0 0;
    
    > li{
      padding: 0;

      input{
        border-radius: 0 $radius $radius 0;
      }

      .react-select{
        .react-select__control{
          border-radius: $radius 0 0 $radius;
          border-right: none !important;

          &.react-select__control--menu-is-open{
            border-radius: $radius 0 0 0;
          }

          .react-select__value-container{
            padding: 0 0 0 15px;
          }
        }
      }

      label.text{
        top: 15px;
        left: 15px;

        &.focus{
          top: -7px;
          left: 10px;
        }
      }
    }
  }
}

.react-select__menu-portal{
  z-index: 10 !important;

  .react-select__menu{
    border-radius: 0 0 $radius $radius;
    margin: -1px 0 0 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid $colorTwo;
    border-top: none;
    overflow: hidden;

    .react-select__menu-list{
      padding: 0;

      .react-select__option{
        position: relative;
        color: $colorText;
        padding: $spacingDiv2 ($spacing - 5);
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;
        @extend %transition;

        &.react-select__option--is-focused{
          background: $colorBg;
        }

        &.react-select__option--is-selected{
          // color: $colorText;]
          color: #FFF;
          background: $colorOne;

          > .check{
            opacity: 1;
          }
        }

        &.react-select__option--is-disabled{
          // display: none;
          opacity: .5;
        }
      }
    }
  }
}

ul.qty{
  margin: 0;

  > li{
    padding: 0;

    > .btn{
      height: 45px;
      line-height: normal;
      padding: 0;
    }

    &:first-child{
      > .btn{
        border-radius: ($radius - 1) 0 0 ($radius - 1);
      }
    }

    &:nth-child(2){
      text-align: center;
      background: #FFF;
      line-height: 43px;
      font-size: 14px;
      border-top: 1px solid rgba($colorText, .25);
      border-bottom: 1px solid rgba($colorText, .25);
    }

    &:last-child{
      > .btn{
        border-radius: 0 ($radius - 1) ($radius - 1) 0;
      }
    }
  }
}

.btn{
  display: block;
  position: relative;
  text-align: center;
  color: #FFF;
  background: $colorOne;
  border-radius: $radius;
  font-weight: bold;
  font-size: 16px;
  height: 50px;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 $spacing;
  box-shadow: 0 2.5px 5px rgba(0, 0, 0, .1);

  &:hover{
    color: #FFF;
    background: $colorOne;
  }

  &.btn-outline{
    background: #FFF;
    border: 1px solid $colorOne;
    color: $colorOne;

    &:hover{
      color: #FFF;
      border: 1px solid $colorOne;
      background: $colorOne;
    }
  }

  // &.btn-loading{
  //   pointer-events: none;
  // }

  &[disabled]{
    background: rgba($colorText, .3);
    color: #FFF;
  }

  > svg{
    margin: -2.5px 0 0 0;
    height: 16px;

    &.before{
      margin-right: 5px;
    }
  }

  > .loading-sec{
    padding: 0;
  }

  // > ul.loading-btn{
  //   position: absolute;
  //   top: 50%;
  //   transform: translate(0, -50%);
  //   left: 0;
  //   right: 0;
  //   margin: 0 auto;
  //   text-align: center;

  //   > li{
  //     display: inline-block;
  //     vertical-align: middle;
  //     background: #FFF;
  //     height: 10px;
  //     width: 4px;
  //     margin: 0 3px;
  //     animation: btn-anim 0.6s infinite ease-in-out;

  //     &:nth-child(1){animation-delay: -0.4s;}

  //     &:nth-child(2){animation-delay: -0.2s;}

  //     &:nth-child(3){animation-delay: 0}
  //   }
  // }
}

// @keyframes btn-anim{
//   0% { transform: translate(0, -5px); }
//   50% { transform: translate(0, 5px); }
//   100% { transform: translate(0, -5px); }
// }

.Toastify__toast-container{
  padding: 0 !important;
  // max-width: $maxWidth !important;

  &.Toastify__toast-container--top-center{
    top: 0;
  }

  > .Toastify__toast{
    padding: $spacing;
    margin: $spacing;
    background: $colorText;
    border-radius: $radius;
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, .25);

    > .Toastify__toast-body{
      padding: 0;
      margin: 0;

      > .Toastify__toast-icon{
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 32px;
        background: $colorOne;

        > svg{
          color: #FFF;
          position: absolute;
          height: 12px;
          top: 50%;
          transform: translate(0, -50%);
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      > div{
        font-family: 'Axiforma', Helvetica, Arial, sans-serif;
        // color: $colorText;
        color: rgba(255, 255, 255, .6);
        font-size: 12px;
        line-height: 16px;

        > span{
          display: block;
          font-size: 16px;
          line-height: 20px;
          color: #FFF;
          // font-weight: 600;
          // color: $colorOne;
        }
      }
    }

    > .Toastify__close-button{
      opacity: 1;
      color: #FFF;
    }

    &.Toastify__toast--success{
      > .Toastify__toast-body{
        > .Toastify__toast-icon{
          background: $colorSuccess;
        }

        // > div{
        //   > span{
        //     color: $colorSuccess;
        //   }
        // }
      }
    }

    &.Toastify__toast--error{
      > .Toastify__toast-body{
        > .Toastify__toast-icon{
          background: $colorError;
        }

        // > div{
        //   > span{
        //     color: $colorError;
        //   }
        // }
      }
    }
  }
}

.loading{
  // position: absolute;
  // top: 50%;
  // left: 0;
  // right: 0;
  // transform: translate(0, -50%);
  // color: $colorText;
  text-align: center;
  margin: $spacing auto $spacingMul2 auto;
  color: $colorLabel;

  > svg{
    display: block;
    width: 115px;
    height: 115px;
    padding: 15px;
    margin: 0 auto;
    
    > path{
      transform-origin: center center;
    }

    .log-1{
      animation: rotate-left 1.0s infinite ease-in-out;
    }

    .log-4{
      animation: rotate-left-top 1.0s infinite ease-in-out;
    }

    .log-2{
      animation: rotate-right 1.0s infinite ease-in-out;
    }

    .log-3{
      animation: rotate-right-top 1.0s infinite ease-in-out;
    }

    .log-5{
      // x: 16.49px;
      // y: 31.56px;
      animation: square 1.0s infinite ease-in-out;
    }
  }
}

@keyframes rotate-left{
  0% { opacity: 0; transform: rotate(180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes rotate-left-top{
  0% { opacity: 0; transform: rotate(-180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes rotate-right{
  0% { opacity: 0; transform: rotate(-180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes rotate-right-top{
  0% { opacity: 0; transform: rotate(180deg) scale(.5); }
  80% { opacity: 1; transform: rotate(0) scale(1); }
  100% { opacity: 0; transform: rotate(0) scale(1.1); }
}

@keyframes square{
  0% { opacity: 0; x: 22.49px; y: 26.56px; width: 1.89px; height: 1.89px; }
  80% { opacity: 1; x: 16.49px; y: 31.56px; width: 2.89px; height: 2.89px; }
  100% { opacity: 0; x: 14.49px; y: 33.56px; width: 2.89px; height: 2.89px; }
}

.loading-sec{
  padding: $spacing;

  > ul{
    text-align: center;
    margin: -2.5px;
  
    > li{
      display: inline-block;
      vertical-align: middle;
      background: $colorOne;
      width: 5px;
      height: 10px;
      margin: 2.5px;
      border-radius: 1px;
      animation: stripe .9s infinite ease-in-out;

      &:nth-child(1){
        animation-delay: -.6s;
      }

      &:nth-child(2){
        animation-delay: -.4s;
      }

      &:nth-child(3){
        animation-delay: -.2s;
      }
    }
  }
}

@keyframes stripe{
  0% { opacity: 0; transform: scale(1, 1); }
  50% { opacity: 1; transform: scale(1, 2); }
  100% { opacity: 0; transform: scale(1, 1); }
}

.container{
  position: relative;
  // max-width: $maxWidth;
  margin: 0 auto;
  background: $colorBg;
  // min-height: 100vh;
  overflow: hidden;
  clear: both;
  z-index: 0;
}

.your-order{
  > ul{
    padding-top: $spacing - 5;
    margin: -$spacingDiv4;
  
    > li{
      padding: $spacingDiv4;
  
      > ul{
        margin: -$spacingDiv4;
  
        > li{
          padding: $spacingDiv4;
          font-size: 14px;
          line-height: 18px;
  
          &:nth-child(1){
            width: 55%;

            > span{
              display: inline-block;
              vertical-align: middle;
              color: $colorLabel;
              font-weight: bold;
              font-size: 12px;
              margin: -2px 0 0 0;
              // line-height: 18px;
            }

            > strong{
              display: block;
              font-size: 11px;
              line-height: 15px;
              color: $colorLabel;
              margin: 1px 0 0 0;
            }
          }
  
          &:nth-child(2){
            width: 15%;
            text-align: right;
            color: $colorLabel;
          }
  
          &:nth-child(3){
            width: 30%;
            text-align: right;

            &.disc{
              color: $colorOne;
            }

            > del{
              display: block;
              font-size: 12px;
              line-height: 16px;
              color: $colorLabel;
            }
          }
        }
      }
  
      &.sum-head{
        > ul{
          > li{
            font-weight: bold;
            color: $colorLabel;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
  
      &.the-trip{
        > ul{
          > li{
            width: 100%;
            font-size: 16px;
            line-height: 20px;
  
            > strong{
              display: block;
              font-size: 12px;
              line-height: 16px;
              color: $colorLabel;
            }
          }
        }
      }
  
      &.the-total{
        > ul{
          > li{
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
  
      &.separation{
        > div{
          border-top: 1px dashed rgba($colorText, .25);
          margin-top: $spacingDiv4;
          padding-bottom: $spacingDiv4;
        }
      }
    }
  }
  
  > .enquiry{
    background: $colorBg;
    padding: $spacing;
    border-radius: $radius;
    margin: $spacing 0 0 0;
    line-height: 16px;

    > strong{
      display: block;
      margin: 0 0 $spacingDiv4 0;
      font-size: 14px;
      line-height: 18px;
    }

    > p{
      margin: $spacingDiv2 0 0 0;
    }

    a{
      font-weight: bold;
    }
  }

  > .data{
    padding: 0 $spacing;
    text-align: center;
    font-size: 11px;
    line-height: 15px;
    color: $colorLabel;
    margin-top: $spacing - 5;

    > a{
      font-weight: bold;
      color: $colorTwo;
    }
  }
}

.title-result{
  position: relative;
  padding: ($spacingMul2 + 10) $spacing 0 $spacing;
  margin-bottom: -$spacing * 3;
  z-index: 1;

  .sec-white{
    margin: 0;
    background: $colorSuccess;
    text-align: center;
    padding: $spacing;
    border-radius: $radius;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);

    > div{
      position: relative;
      color: #FFF;
      width: 60px;
      height: 60px;
      margin: (-$spacing - 30) auto $spacingDiv2 auto;

      &:after,
      &:before{
        content: '';
        position: absolute;
        background: $colorSuccess;
        left: 0;
        right: 0;
        width: 60px;
        height: 60px;
        border-radius: 60px;
        border: 5px solid #FFF;
        box-sizing: border-box;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
        z-index: 0;
        animation: circle-anim 1.8s infinite ease-in-out;
      }

      // &:after{}

      &:before{
        animation-delay: -.9s;
      }

      @keyframes circle-anim{
        0% { opacity: 0; transform: scale(.75); border: 5px solid #FFF; }
        50% { opacity: 1; transform: scale(1); border: 5px solid #FFF; }
        100% { opacity: 0; transform: scale(1.5); border: 0 solid #FFF; }
      }

      > svg{
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        transform-origin: center center;
        // transform: translate(0, -50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
        animation: check-anim 1.8s infinite ease-in-out;
      }

      @keyframes check-anim{
        0% { opacity: 0; transform: rotate(-20deg) scale(.75) translate(0, -50%); }
        25% { opacity: 1; transform: rotate(0) scale(1) translate(0, -50%); }
        75% { opacity: 1; transform: rotate(0) scale(1) translate(0, -50%); }
        100% { opacity: 0; transform: rotate(20deg) scale(.75) translate(0, -50%); }
      }
    }

    > h2{
      color: #FFF;
      font-size: 18px;
      line-height: 22px;

      > span{
        color: #FFF;
      }
    }
  }
}

ul.detail-info{
  padding-top: $spacing - 5;
  margin: -$spacingDiv4;

  > li{
    position: relative;
    font-size: 14px;
    line-height: 18px;
    padding: $spacingDiv4 $spacingDiv4 $spacingDiv4 31px;

    > label{
      display: block;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      color: $colorLabel;
    }

    > svg{
      position: absolute;
      width: 21px;
      height: 16px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      color: rgba($colorLabel, .55);
    }

    > div{
      font-size: 13px;
      line-height: 17px;
    }

    > ul{
      margin: $spacingDiv4 0 0 0;
      padding: 0 0 0 $spacingDiv2;
      border-left: 2px solid rgba($colorText, .25);

      > li{
        padding: $spacingDiv8;
        font-size: 13px;
        line-height: 17px;

        > label{
          color: rgba($colorLabel, .5);
          display: block;
          font-weight: bold;
          font-size: 11px;
          line-height: 15px;
          // display: inline-block;
          // margin: 0 $spacingDiv4 0 0;
        }
      }
    }
  }
}

.order-det{
  padding-top: $spacingMul2 * 2;
}

.popup-overlay{
  padding: $spacing;

  .popup-content{
    width: auto;
    height: auto;
    padding: $spacing;
    // padding: 0;
    border: none;
    border-radius: $radius;
    box-sizing: border-box;
    overflow: scroll;

    .modal{
      overflow: hidden;
      // border-radius: $radius;

      > .slick-slider{
        width: 80vw;
        max-width: 500px;
        
        .slick-prev,
        .slick-next{
          background: $colorOne;
          padding: 5px;
          width: 30px;
          height: 30px;
          z-index: 1;
          box-sizing: border-box;

          &:before{
            line-height: 20px;
            width: 20px;
            height: 20px;
          }
        }

        .slick-prev{
          left: 0;
          border-radius: 0 ($radius / 2) ($radius / 2) 0;
        }

        .slick-next{
          right: 0;
          border-radius: ($radius / 2) 0 0 ($radius / 2);
        }

        > .slick-list{
          > .slick-track{
            > div{
              > div{
                > div{
                  position: relative;
                  height: 0;
                  padding: 50% 0;

                  > img{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px){
  .container{
    max-width: 1100px;
  }

  section{
    &.sec-white{
      margin: 0 auto 20px auto;
      border-radius: $radius;

      &.bookhead{
        // margin: 20px auto;
        max-width: 1000px;
        // border-radius: 0 0 $radius $radius;
      }

      &.order-button,
      &.sec-bottom{
        max-width: 1000px;
        border-radius: $radius $radius 0 0;
      }

      &.has-max{
        max-width: 600px;
      }
    }
  }

  .title-result{
    .sec-white{
      margin: 0 auto;
      max-width: 500px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  // section{
  //   &.sec-white{
  //     max-width: 600px;
  //     margin: 0 auto 15px auto;
  //     border-radius: $radius;

  //     &.bookhead{
  //       border-radius: 0 0 $radius $radius;
  //     }

  //     &.order-button,
  //     &.sec-bottom{
  //       border-radius: $radius $radius 0 0;
  //     }
  //   }
  // }

  // .title-result{
  //   .sec-white{
  //     margin: 0 auto;
  //     max-width: 500px;
  //   }
  // }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  section{
    &.sec-white{
      max-width: 500px;
      margin: 0 auto 15px auto;
      border-radius: $radius;

      &.bookhead{
        margin: 15px auto;
        // border-radius: 0 0 $radius $radius;
      }

      &.order-button,
      &.sec-bottom{
        border-radius: $radius $radius 0 0;
      }
    }
  }

  .title-result{
    .sec-white{
      margin: 0 auto;
      max-width: 400px;
    }
  }
}

.offline{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  padding: $spacing;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: $colorLabel;

  > span{
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: $colorText;
  }
}

.back-home{
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  margin: 20px 0 0 0;
}

@media screen and (max-width: 767px){
  header{
    padding: 15px 0 0 0;

    > .navmobile{
      display: block;
    }

    > a{
      height: 51px;
      // width: 180px;
      width: 124px;

      > svg{
        margin: 15px 0 0 0;
      }
    }

    > ul{
      // display: none;
      padding: 20px 0 0 0;

      > li{
        float: none !important;
        width: 100% !important;

        > a{
          position: absolute;
          top: 20px;
          right: 10px;
          font-size: 12px;
          height: 33px;
          line-height: 33px;
          padding: 0 10px;
          border-radius: 10px;
        }

        > ul{
          position: absolute;
          max-height: 0;
          overflow: hidden;
          left: 0;
          right: 0;
          background: #FFF;

          &.open{
            // display: block;
            max-height: 1000px;
          }

          > li{
            width: 100%;
            padding: 0;

            > a,
            > button{
              text-align: left;
              font-size: 15px;
              line-height: 19px;
            }

            &:after{
              display: none;
            }

            > ul{
              position: static;
              width: 100%;
              top: 38px;
              padding: 0 0 0 10px;

              > li{
                > a{
                  font-size: 15px;
                  line-height: 19px;
                }
              }
            }
          }
        }
      }
    }
  }

  footer{
    .footer-top{
      padding: 40px 20px 20px 20px;

      > ul{
        margin: -20px -10px;

        > li.lay-25{
          width: 100%;
          padding: 20px 10px;
        }
      }

      .foot-btm{
        padding: 20px 0 0 0;

        > div{
          width: 45%;
          font-size: 14px;
          line-height: 16px;

          > ul{
            margin: -2.5px -5px;
            padding: 10px 0 0 0;

            > li{
              padding: 2.5px 5px;

              > a{
                width: 24px;
                height: 24px;
                padding: 6px;

                > svg{
                  margin: -10px 0 0 0;
                }
              }
            }
          }
        }
      }
    }

    .footer-btm{
      font-size: 12px;
      line-height: 16px;

      > br{
        display: block;
      }
    }
  }
}

@media screen and (max-width: 767px) and (orientation: landscape){
  section{
    &.sec-white{
      max-width: 500px;
      margin: 0 auto 15px auto;
      border-radius: $radius;

      &.bookhead{
        margin: 15px auto;
        // border-radius: 0 0 $radius $radius;
      }

      &.order-button,
      &.sec-bottom{
        border-radius: $radius $radius 0 0;
      }
    }
  }

  .title-result{
    .sec-white{
      margin: 0 auto;
      max-width: 400px;
    }
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){}

// @media screen and (min-width: 1025px){}

// @media screen and (min-width: 768px) and (max-width: 1024px){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

// @media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

// @media screen and (max-width: 767px){}

// @media screen and (max-width: 767px) and (orientation: landscape){}

// @media screen and (max-width: 767px) and (orientation: portrait){}